<template>
  <div class="CustomType page">
    <div style="margin-bottom: 15px">
      <a-input
        placeholder="电话号码"
        style="width: 180px; margin-right: 20px"
      ></a-input>
      <a-button type="primary">
        <template #icon><SearchOutlined /></template>
        搜索
      </a-button>
    </div>
    <div style="margin-bottom: 20px">
      <a-button type="primary" @click="handleAdd(0)">添加</a-button>
      <a-button type="primary" @click="handleAdd(1)">批量导入</a-button>
      <label>* 创建任务时，若添加的电话号码存在于黑名单中，则不会呼出 </label>
    </div>
    <a-table :columns="columns" :data-source="blackList">
      <template #operation="{ record }">
        <!-- <a @click="onDelete(record.key)">Delete</a> -->
        <a-button type="primary" size="small" @click="handleEdit(record)"
          >编辑</a-button
        >
        <a-button size="small" @click="handleDelete(record.key)">删除</a-button>
      </template>
    </a-table>
    <a-modal
      v-model:visible="visible"
      title="添加客户类型"
      @ok="handleOk"
      centered
      okText="确定"
      cancelText="取消"
      :maskClosable="false"
      class="customModel"
    >
      <!-- <a-form :model="formState">
        <a-form-item label="客户类型">
          <a-input placeholder="请输入" v-model:value="formState.name" />
        </a-form-item>
        <a-form-item label="按键">
          <a-select placeholder="请选择" v-model:value="formState.address">
            <a-select-option value="11">Zone one</a-select-option>
            <a-select-option value="22">Zone two</a-select-option>
            <a-select-option value="00">Zone 辅导费</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="排序">
          <a-input placeholder="请输入" v-model:value="formState.sort" />
        </a-form-item>
      </a-form> -->
      <a-form :model="formState">
        <!-- <a-form-item label="客户类型">
          <a-input placeholder="请输入" v-model:value="formState.add_type" />
        </a-form-item> -->
        <a-form-item label="客户名称" required="">
          <a-input placeholder="请输入" v-model:value="formState.user_id" />
        </a-form-item>
        <a-form-item label="手机号码" required>
          <a-input placeholder="请输入" v-model:value="formState.phone" />
        </a-form-item>
      </a-form>
    </a-modal>
    <a-modal
      v-model:visible="visibleDelete"
      title="信息"
      @ok="handleOkDelete"
      centered
      okText="确定"
      cancelText="取消"
      :maskClosable="false"
      :width="300"
    >
      <p>确认删除吗？</p>
    </a-modal>
        <a-modal
      v-model:visible="visible2"
      title="导入客户"
      centered
      :maskClosable="false"
      okText="确定"
      cancelText="取消"
      :width="500"
      @ok="okDaoru"
    >
      <div style="margin-left: 20px">
        <a-upload
          :file-list="file"
          :remove="handleRemove"
          :before-upload="beforeUpload"
        >
          <a-button>
            <upload-outlined></upload-outlined>
            选择文件
          </a-button>
        </a-upload>
        <!-- <div style="color: red">(建议单次导入号码数量不要超过二十万条)</div> -->
        <div>
          *Excel文件必须是.csv或.xlsx扩展名, csv文件请用GBK或UTF-8格式保存
          <a @click="download" style="color: red">模板下载</a>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { reactive, toRefs } from "@vue/reactivity";
import { SearchOutlined } from "@ant-design/icons-vue";
import { postBlackAdd, getBlackList } from "../../service/crmBlackList";
import { regTest } from "../../utils/regExp";
import { message } from "ant-design-vue";
import { onMounted } from "@vue/runtime-core";
const columns = [
  {
    title: "ID",
    dataIndex: "id",
  },
  {
    title: "客户",
    dataIndex: "user_id",
  },
  // {
  //   title: "按键",
  //   dataIndex: "address",
  // },
  {
    title: "手机号码",
    dataIndex: "phone",
  },
  {
    title: "操作时间",
    dataIndex: "create_time",
  },
  {
    title: "操作",
    // key: "operation",
    fixed: "right",
    // width: 100,
    dataIndex: "operation",
    slots: {
      customRender: "operation",
    },
  },
];
export default {
  name: "CustomType",
  components: {
    SearchOutlined,
  },
  setup() {
    const state = reactive({
      visible: false,
      visible2:false,
      visibleDelete: false,
      deleteId: undefined,
      page: 1,
      limit: 20,
      blackList: [],
      file:[]
    });
    const formState = reactive({
      add_type: undefined,
      phone: undefined,
      remark: "aaa",
      user_id: undefined,
    });
    const handleAdd = (num) => {
      if (num == 0) {
        state.add_type = num;
        state.visible = true;
      } else {
        state.add_type = num;
        state.visible2 = true;
      }
    };
    const reset = () => {
      (formState.add_type = undefined),
        (formState.phone = undefined),
        (formState.user_id = undefined);
    };
    const handleRemove = () => {
      state.file = [];
    };
    const init = async () => {
      const res = await getBlackList({
        access_token: sessionStorage.getItem("token"),
        page: state.page,
        limit: state.limit,
      });
      // console.log(res);
      state.blackList = res.data.data;
    };
    onMounted(() => {
      init();
    });
    //添加
    const blackAdd = async () => {
      const res = await postBlackAdd({
        access_token: sessionStorage.getItem("token"),
        ...formState,
      });
      console.log(res.data);
      let res1 = JSON.parse(res.data.substring(0, res.data.indexOf("}") + 1));
      if (res1.status == 100000) {
        message.success(res1.msg);
        init();
        return;
      } else {
        message.error(res1.msg);
        return;
      }
    };
    const handleOk = () => {
      if (!formState.user_id) {
        message.warning("客户名称不能为空");
        return;
      } else if (!formState.phone) {
        message.warning("手机号不能为空");
        return;
      } else if (!regTest(formState.phone)) {
        message.warning("手机号格式有误");
        return;
      }
      blackAdd();
      reset();
      state.visible = false;
    };
    const beforeUpload = (file) => {
      state.file = [file];
      return false;
    };
    const okDaoru = async () => {
      const formData = new FormData();
      console.log(formData);
      formData.append("file", state.file[0]);
      formData.append("type", "public");
      formData.append("access_token", sessionStorage.getItem("token"));
      const res = await postBlackAdd(formData);
      console.log(res);
      let res1 = JSON.parse(res.data.substring(0, res.data.indexOf("}") + 1));
      if (res1.status == 100000) {
        message.info(
          `成功导入 ${res1.data.s_num}条，重复 ${res1.data.r_num}条`
        );
        // message.success(`成功导入 ${res.data.data.s_num}条`);
        // message.warning(`重复 ${res.data.data.r_num}条`);
        // message.warning(res.data.msg);
        // message.error(res.data.msg);
        state.visible2 = false;
        state.file = [];
        init()
      } else {
        message.error(res1.msg);
      }
    };

    const handleEdit = (record) => {
      console.log(record);
      state.visible = true;
      for (let key in record) {
        formState[key] = record[key];
      }
    };
    const handleDelete = (id) => {
      state.visibleDelete = true;
      state.deleteId = id;
    };
    const handleOkDelete = () => {
      state.visibleDelete = false;
      console.log("删除它");
    };
    return {
      columns,
      handleAdd,
      ...toRefs(state),
      formState,
      handleOk,
      handleEdit,
      handleDelete,
      handleOkDelete,
      okDaoru,
      beforeUpload,
      handleRemove
    };
  },
};
</script>

<style>
.customModel .ant-form-item-label {
  min-width: 70px;
}
</style>
<style scoped>
.CustomType {
  padding: 15px;
}
.ant-btn-primary {
  margin-right: 4px;
}
</style>